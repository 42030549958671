import OwlCarousel from "react-owl-carousel";
import "./Testimonials.css";
import { useContext, useState } from "react";
import Title from "../Title/Title";
import { LangContext } from "../../../Context/LangContext";
import StarRatings from "react-star-ratings";

function ItemsSlider(props) {
  const [rating, setRating] = useState(5);

  return (
    <div className="items">
      <div
        className="sub-testimonials"
        data-aos="fade-up"
        data-aos-easing="linear"
        data-aos-duration="700"
      >
        <div className="img-testimonials">
          <img src={props.img} alt={props.name} />
        </div>

        <div className="text-testimonials">
          <h2> {props.name} </h2>
          <StarRatings
            className="m-3"
            rating={rating}
            starRatedColor="rgb(247, 192, 89)"
            starEmptyColor="#f5f5f5"
            starHoverColor="rgb(239, 172, 48)"
            starSpacing="2"
            starDimension="24"
            svgIconViewBox="0 0 30 30"
            svgIconPath="M13.73 3.51001L15.49 7.03001C15.73 7.52002 16.37 7.99001 16.91 8.08001L20.1 8.61001C22.14 8.95001 22.62 10.43 21.15 11.89L18.67 14.37C18.25 14.79 18.02 15.6 18.15 16.18L18.86 19.25C19.42 21.68 18.13 22.62 15.98 21.35L12.99 19.58C12.45 19.26 11.56 19.26 11.01 19.58L8.01997 21.35C5.87997 22.62 4.57997 21.67 5.13997 19.25L5.84997 16.18C5.97997 15.6 5.74997 14.79 5.32997 14.37L2.84997 11.89C1.38997 10.43 1.85997 8.95001 3.89997 8.61001L7.08997 8.08001C7.61997 7.99001 8.25997 7.52002 8.49997 7.03001L10.26 3.51001C11.22 1.60001 12.78 1.60001 13.73 3.51001Z"
            changeRating={(newRating) => setRating(newRating)}
            numberOfStars={5}
            name="rating"
          />
          <p> {props.des} </p>
        </div>
      </div>
    </div>
  );
}
export default function Testimonials() {
  const langCtx = useContext(LangContext);

  return (
    <div className="testimonials pg-section" id="testimonials">
      <div className="main-container">
        <Title name={text.title[langCtx]} />
        <div className="slider-testimonials">
          <OwlCarousel
            key={langCtx}
            dir="ltr"
            className="owl-theme"
            items={4}
            loop
            autoplayHoverPause
            autoplay
            dots={true}
            margin={10}
            nav={false}
            responsive={{
              150: {
                items: 1,
              },

              768: {
                items: 2,
              },
              992: {
                items: 3,
              },
              1200: {
                items: 4,
              },
            }}
          >
            <ItemsSlider
              name="AMAALA"
              img="/images/cl1.svg"
              des={text.DesClinet[langCtx]}
            />
            <ItemsSlider
              name="MBL"
              img="/images/c2.png"
              des={text.DesClinet2[langCtx]}
            />
            <ItemsSlider
              name="FLOW "
              img="/images/c3.png"
              des={text.DesClinet3[langCtx]}
            />
            <ItemsSlider
              name="SIC "
              img="/images/c4.png"
              des={text.DesClinet4[langCtx]}
            />
          </OwlCarousel>
        </div>
      </div>
    </div>
  );
}

const text = {
  DesClinet: {
    en: " served as Emergency Response Manager at FAST CONSORTIUM from December 2015 to March 2021. In March 2016, I partnered with MOMENT CARE MEDICAL Co. for emergency medical services on the Riyadh Metro Project. Their excellent service is highly recommended. I later joined RED SEA GLOBAL. ",
    ar: " عملت كمدير للاستجابة الطارئة في FAST CONSORTIUM من ديسمبر 2015 إلى مارس 2021. في مارس 2016، شركة لحظة الرعاية الطبية أصبحت موردًا رئيسيًا لخدمات الرعاية الطبية الطارئة على مشروع مترو الرياض. يُوصى بشدة بخدماتهم الممتازة. انتقلت لاحقًا للانضمام إلى RED SEA GLOBAL. ",
  },
  DesClinet2: {
    en: " We, Modern Building Leaders (MBL) Company, certify that the mentioned company has provided satisfactory medical services, including qualified nurses, doctors, ambulances, and ambulance drivers, from May 20, 2020, until now. This certificate is issued at the company's request without any liability to us. ",
    ar: " نحن، شركة قادة البناء الحديثة (MBL)، نشهد أن الشركة المذكورة قد قدمت خدمات طبية مرضية، بما في ذلك توفير ممرضات وأطباء مؤهلين، وسيارات إسعاف، وسائقي سيارات الإسعاف، منذ 20 مايو 2020 وحتى الآن. تم إصدار هذا الشهادة بناءً على طلب الشركة دون أي مسؤولية على مسؤوليتنا ",
  },
  DesClinet3: {
    en: " We, the Health, Safety, and Environmental Team at Riyadh Metro Operator, certify that the mentioned company has satisfactorily provided us with medical services (supply of qualified nurses/paramedics) through a service agreement dated May 2020. Company Name: Manar Medical Care (now known as Moment Medical Care Company) Nationality: Saudi Arabian. ",
    ar: " نحن، فريق الصحة والسلامة والبيئة في مشغل مترو الرياض، نشهد بأن الشركة المذكورة أدت بشكل مرضٍ إلى توفير خدمات طبية لنا (توفير ممرضين/مسعفين مؤهلين) من خلال اتفاقية خدمة مؤرخة في مايو 2020.",
  },
  DesClinet4: {
    en: " We, Soil Improvement Contracting Company (SIC), hereby certify that the below-mentioned Company has satisfactorily provided us with Medical Services (supply of qualified nurse / paramedic) through a Service Agreement dated 1st August 2022 ",
    ar: " نحن، شركة تحسين التربة للمقاولات (SIC)، نشهد بأن الشركة المذكورة أدت بشكل مرضٍ إلى توفير خدمات طبية لنا (توفير ممرض/مسعف مؤهل) من خلال اتفاقية خدمة مؤرخة في 1 أغسطس 2022",
  },

  title: {
    en: "Testimonials",
    ar: "اراء العملاء",
  },
};
