import Modal from "react-bootstrap/Modal";
import Iframe from "react-iframe";
import "./Model.css"
import { useContext } from "react";
import { LangContext } from "../../../Context/LangContext";
export default function Model(props) {
  const langCtx = useContext(LangContext);

  return (
    <Modal
      size="lg"
      show={props.lgShow}
      onHide={() => props.setLgShow(false)}
      aria-labelledby="example-modal-sizes-title-lg"
      style={langCtx === "ar" ? { direction: "rtl" } : { direction: "ltr" }}
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">{props.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={ langCtx === "ar"  ? { direction: "rtl"} : {direction: "ltr"}}>
        <Iframe
          src={text.BookyLink[langCtx]}
          width="100%"
          height="600px"
        ></Iframe>
      </Modal.Body>
    </Modal>
  );
}

const text = {
  
  BookyLink: {
      en: "https://wa.me/966583071540",
      ar: "https://wa.me/966583071540",
  },
}
