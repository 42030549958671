import { useContext } from "react";
import "./WhatsApp.css";
import { LangContext } from "../../../Context/LangContext";

export default function Whatsapp() {
    const langCtx = useContext(LangContext);

  return (
    <div className="whatsapp-link">
      <a href="https://wa.me/966583071540" style={langCtx === "ar" ? { left : '30px'} : {right: '30px'}}  target="_blank">
        <i class="bi bi-whatsapp"></i>
      </a>
    </div>
  );
}
