import OwlCarousel from "react-owl-carousel";
import { useContext } from "react";
import { LangContext } from "../../../Context/LangContext";
import "./Services.css";
import Title from "../Title/Title";
function ItemsSlider(props) {
  return (
    <div
      className="items"
      data-aos="fade-up"
      data-aos-easing="linear"
      data-aos-duration="700"
    >
      <div className="sub-services">
        <div className="img-services">
          <img src={props.img} alt={props.name} />
        </div>
        <div className="text-services">
          <h2> {props.name} </h2>
          <p> {props.des} </p>
        </div>
      </div>
    </div>
  );
}

export default function Services() {
  const langCtx = useContext(LangContext);
  console.log(text.Medical[langCtx]);
  return (
    <section className="services pg-section" id="services">
      <div className="main-container">
        <Title name={text.title[langCtx]} />
        <div className="slider-services">
          <OwlCarousel
            key={langCtx}
            dir="ltr"
            className="owl-theme"
            items={3}
            loop
            autoplayHoverPause
            autoplay
            dots={false}
            margin={10}
            nav
            responsive={{
              300: {
                items: 1,
                nav: false,
              },
              768: {
                items: 2,
              },
              992: {
                items: 3,
              },
            }}
          >
            <ItemsSlider
              img="/images/s1.png"
              key={5}
              name={text.Medical[langCtx]}
              des={text.dacMedical[langCtx]}
            />
            <ItemsSlider
              key={1}
              img="/images/s2.png"
              name={text.TitleServices[langCtx]}
              des={text.DesServices[langCtx]}
            />
            <ItemsSlider
              key={2}
              img="/images/s3.png"
              name={text.TitleServices2[langCtx]}
              des={text.DesServices2[langCtx]}
            />

            <ItemsSlider
              key={3}
              img="/images/s5.png"
              name={text.TitleServices4[langCtx]}
              des={text.DesServices4[langCtx]}
            />
            <ItemsSlider
              key={4}
              img="/images/s6.png"
              name={text.TitleServices5[langCtx]}
              des={text.DesServices5[langCtx]}
            />
            <ItemsSlider
              key={6}
              img="/images/s4.png"
              name={text.TitleServices3[langCtx]}
              des={text.DesServices3[langCtx]}
            />
          </OwlCarousel>
        </div>
      </div>
    </section>
  );
}

const text = {
  Medical: {
    ar: "معدات طبية و مواد استهلاكية، وأدوية طبية.",
    en: "Medical equipment's and consumables and medicine.",
  },
  TitleServices: {
    ar: "خدمة الرعاية الصحية",
    en: "Medical Health Care",
  },
  DesServices: {
    ar: "نقدم الرعاية الصحية و خدمات الرعاية المنزلية.",
    en: "We Provide Medical Health Care and home Care services.",
  },
  TitleServices2: {
    ar: "طاقم طبي مؤهل في موقعك",
    en: "Qualified Medical Staff On Site",
  },
  DesServices2: {
    ar: "نوفر طاقمًا طبيًا مؤهلاً للمواقع والمشاريع مثل الأطباء، الممرضين، المسعفين.",
    en: "We provide Qualified Medical staff to sites, projects like Doctors, Nurses, Ambulance Drives.",
  },
  TitleServices3: {
    ar: "إدارة النفايات الطبية",
    en: "Medical Waste Management",
  },
  DesServices3: {
    ar: "ندير جميع أنواع النفايات الطبية من جميع أنواع العيادات.",
    en: "We are managing all type off medical waste from all type off clinics.",
  },
  TitleServices4: {
    ar: "سيارات الإسعاف",
    en: "Ambulance",
  },
  DesServices4: {
    ar: "نقدم جميع أنواع سيارات الإسعاف للمواقع والمشاريع.",
    en: "We Provide All type of Ambulance on site.",
  },
  TitleServices5: {
    ar: "خدمات المختبر",
    en: "Lab Services",
  },
  DesServices5: {
    ar: "نقدم خدمات المختبر مثل جميع أنواع اختبارات الدم.",
    en: "We Provide Lab services like all type of blood tests.",
  },

  dacMedical: {
    ar: "نقدم معدات، مواد استهلاكية، وأدوية لجميع أنواع العيادات.",
    en: "We provide equipment's, consumables and medicine for all type all clinics.",
  },

  title: {
    ar: "خدماتنا",
    en: "Our Services",
  },
};
