import "./Loader.css";
export default function Loader(props) {
  return (
    <div id="loader" className={props.open ? "active" : ""}>
      <div class="spinner">
        <h3>
          <span class="visually-hidden">visuallyhidden</span>
        </h3>
      </div>
    </div>
  );
}
