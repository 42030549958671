
export default function Title(props) {

    return ( 
        <div className="title-center">
            <h2><span></span>{props.name}<span></span></h2>
        </div>
    )
    
}


