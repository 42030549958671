import Modal from "react-bootstrap/Modal";
import "./Terms.css";
import { useContext } from "react";
import { LangContext } from "../../../Context/LangContext";
export default function Terms(props) {
  const langCtx = useContext(LangContext);

  return (
    <Modal
      size="lg"
      show={props.lgShow}
      onHide={() => props.setLgShow(false)}
      aria-labelledby="example-modal-sizes-title-lg"
      style={langCtx === "ar" ? { direction: "rtl" } : { direction: "ltr" }}
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          {props.name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="mode-Terms">

      <p>
          <div class="elementor-widget-container">
            <div>
              <div>
                <b>
                  <span> {text.titleTerms[langCtx]}</span>
                </b>
              </div>
              <p></p>
              <div dangerouslySetInnerHTML={{ __html: text.disTerms[langCtx] }} />
            </div>
          </div>
      </p>

      </Modal.Body>
    </Modal>
  );
}

const text = {
  titleTerms: {
    en: "Terms and Conditions/Refund Policy",
    ar: "الشروط والأحكام/سياسة الاسترجاع",
  },
  disTerms: {
    en: `
    <b>A) Right to Cancel or Modify a Service:</b>
    <p>You have the right to cancel or modify a service request within three (3) days from the date of service booking confirmation. In case of cancellation, the paid amount will be refunded according to the terms specified below.</p>
    
    <b>B) Conditions for Cancellation and Modification:</b>
    <p>Cancellation or modification must occur before the commencement of the service. Administrative fees or compensatory costs may be imposed if cancellation or modification occurs after a certain period or after the initiation of the service.</p>
    
    <b>C) Additional Expenses in Cases of Cancellation and Modification:</b>
    <p>In case of cancellation after the specified period, the provider may refuse the request or additional charges may be incurred. In case of discounts or special offers, the terms of cancellation and modification may vary.</p>
    
    <b>D) Warranty:</b>
    <p>The warranty only covers defects related to the quality of the service provided. The warranty does not cover defects due to misuse or circumstances beyond the control of the service provider.</p>
    
    <b>Refund of Paid Amount</b>
    <p>In case of cancellation in accordance with the terms of this policy, the paid amount will be refunded using the original payment method.</p>
    
    <b>Cancellation and Modification</b>
    <p>You must inform us immediately through our website or contact us at (+966-58-307-1540) to proceed with the cancellation or modification request. Upon receiving your request, we will review it and notify you of the next steps.</p>
    
    <b>Processing of Requests</b>
    <p>We will process cancellation or modification requests as quickly as possible. You will be informed of any decisions related to your request within thirty (30) days from the date of receiving the request.</p>
  `,
    ar: `
    <div>
      <b>أ) حق الإلغاء أو التعديل للخدمة:</b>
      <p>يحق لك إلغاء أو تعديل طلب الخدمة في غضون ثلاثة (3) أيام من تاريخ تأكيد حجز الخدمة. في حالة الإلغاء، سيتم رد المبلغ المدفوع وفقًا للشروط المحددة أدناه.</p>
      
      <b>ب) شروط الإلغاء والتعديل:</b>
      <p>يجب أن يتم الإلغاء أو التعديل قبل بدء تقديم الخدمة. قد تُفرض رسوم إدارية أو تكاليف تعويضية إذا تم الإلغاء أو التعديل بعد مرور فترة معينة أو بعد بدء تنفيذ الخدمة.</p>
      
      <b>ت) مصاريف إضافية في حالات الإلغاء والتعديل:</b>
      <p>في حالة الإلغاء بعد المدة المحددة، قد يحق للمقدم رفض الطلب أو قد يتم تحميلك تكاليف إضافية. في حالة تقديم خصومات أو عروض خاصة، قد تختلف شروط الإلغاء والتعديل.</p>
      
      <b>ث) الضمان:</b>
      <p>يشمل الضمان فقط العيوب المتعلقة بجودة الخدمة المقدمة. لا يشمل الضمان العيوب الناجمة عن سوء الاستخدام أو الظروف خارجة عن سيطرة مقدم الخدمة.</p>
      
      <b>استرداد المبلغ المدفوع</b>
      <p>في حالة الإلغاء وفقاً لشروط هذه السياسة، سيتم رد المبلغ المدفوع بنفس طريقة الدفع الأصلية.</p>
      
      <b>الإلغاء والتعديل</b>
      <p>يجب إبلاغنا فوراً عبر موقعنا الإلكتروني أو الاتصال بنا على (+966-56-095-6222) لتنفيذ طلب الإلغاء أو التعديل. عند استلام طلبك، سنقوم بمراجعته وإخطارك بالإجراءات التالية.</p>
      
      <b>معالجة الطلبات</b>
      <p>سنقوم بمعالجة طلبات الإلغاء أو التعديل في أسرع وقت ممكن. سيتم إبلاغك بأي قرارات متعلقة بطلبك خلال ثلاثين (30) يوماً من تاريخ استلام الطلب.</p>
    </div>
  `,
  },
  
};
