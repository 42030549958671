import Contactus from "./Contactus/Contactus";
import Footer from "./Footer/Footer";
import Hero from "./Hero/Hero";
import "./Layout.css";
import Navbar from "./Navbar/Navbar";
import Services from "./Services/Services";
import Testimonials from "./Testimonials/Testimonials";
import "../../Style/Responsive.css"
import Loader from "../../Loader/Loader";
import { useEffect, useState } from "react";

export default function Layout() {
  const [loader, setLoader] = useState(false);

  if(loader === false) {
    setTimeout(() => {
      setLoader(true)
    }, 1200)
  }
  return (
    <>
      <Loader open={loader} />
      <div className="layout">
        <header>
          <Navbar setLoader={setLoader}/>
        </header>
        <main>
          <Hero />
          <Services />
          <Testimonials />
          <Contactus />
        </main>
        <Footer />
      </div>
    </>
  );
}

