import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useContext } from "react";
import Title from "../Title/Title";
import { LangContext } from "../../../Context/LangContext";
import "./Contactus.css";

function Contact(props) {
  return (
    <li>
      <div
        className="sub-info-contact"
        data-aos="fade-left"
        data-aos-easing="linear"
        data-aos-duration="700"
      >
        <div className="icon-info-contact">
          <i className={`bi ${props.icon}`}></i>
        </div>
        <div className="text-info-contact">
          <h2> {props.name} </h2>
          <p> {props.des} </p>
        </div>
      </div>
    </li>
  );
}

export default function Contactus() {
  const langCtx = useContext(LangContext);

  return (
    <section className="contactus pg-section" id="contactus">
      <Title name={text.title[langCtx]} />
      <div className="main-contactus">
        <div className="main-container">
          <Row className="align-items-end">
            <Col lg="6">
              <div
                className="img-contactus"
                data-aos="fade-down"
                data-aos-easing="linear"
                data-aos-duration="700"
              >
                <a href="https://www.google.com/maps/place/Moment+Care+Medical+Company/@24.6800161,46.7342118,17.25z/data=!4m6!3m5!1s0x3e2f05e2e2bcaa93:0x9dc249efd4cc9de!8m2!3d24.6801751!4d46.7320595!16s%2Fg%2F11vjjgkgcq?hl=ar-SA&entry=ttu" target="_blank"> 
                  <img src="images/map.png" alt="map" />

                </a>
              </div>
            </Col>

            <Col lg="6">
              <div className="info-contactus">
                <ul>
                  <Contact
                    icon="bi-telephone-fill"
                    name={text.Contact[langCtx]}
                    des="+966-58-307-1540"
                  />
                  <Contact
                    icon="bi-envelope-fill"
                    name={text.Email[langCtx]}
                    des="info@momentcare.com.sa"
                  />
                  <Contact
                    icon=" bi-geo-alt-fill"
                    name={text.address_name[langCtx]}
                    des={text.address[langCtx]}
                  />
                </ul>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </section>
  );
}
const text = {
  Contact: {
    en: "Contact us",
    ar: "اتصل بنا",
  },
  Email: {
    en: "Email",
    ar: "البريد الالكتروني",
  },
  address_name: {
    en: "Address",
    ar: "الموقع",
  },
  address: {
    en: "Malaz district , Omar Bin Abdul Aziz Street Riyadh City.",
    ar: "حي الملز شارع عمر بن عبد العزيز مدينة الرياض.",
  },

  title: {
    en: "Contact us",
    ar: "تواصل معنا  ",
  },
};
