import Modal from "react-bootstrap/Modal";
import "./Privacy.css";
import { useContext } from "react";
import { LangContext } from "../../../Context/LangContext";
export default function Privacy(props) {
  const langCtx = useContext(LangContext);

  return (
    <Modal
      size="lg"
      show={props.lgShow}
      onHide={() => props.setLgShow(false)}
      aria-labelledby="example-modal-sizes-title-lg"
      style={langCtx === "ar" ? { direction: "rtl" } : { direction: "ltr" }}
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          {props.name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="mode-Privacy">
        <p>
          <div class="elementor-widget-container">
            <div>
              <div>
                <b>
                  <span> {text.titlePrivacy[langCtx]}</span>
                </b>
              </div>
              <p></p>
              <div>{text.disPrivacy[langCtx]}</div>
              <p></p>
              <div>
                <b>{text.dis2[langCtx]}</b>
                <br />
              </div>
              <p></p>
              <div>{text.dis3[langCtx]}</div>
              <p></p>
              <div>{text.dis4[langCtx]}</div>
              <p></p>
              <div>{text.dis5[langCtx]}</div>
              <p></p>
              <div>
                <b>{text.dis6[langCtx]}</b>
              </div>
              <p></p>
              <div>{text.dis7[langCtx]}</div>
              <p> {text.dis8[langCtx]}</p>
              <div>{text.dis9[langCtx]}</div>
              <p></p>
              <div>{text.dis10[langCtx]}</div>
              <p> </p>
              <div>
                <b></b>
              </div>
              <p>{text.dis11[langCtx]} </p>
              <div>{text.dis12[langCtx]}</div>
              <p></p>
              <div>{text.dis13[langCtx]}</div>
              <p></p>
              <div>{text.dis14[langCtx]}</div>
              <p></p>
              <div>{text.dis15[langCtx]}</div>
              <p></p>
              <div>
                <b>{text.dis16[langCtx]}</b>
              </div>
              <p></p>
              <div>{text.dis17[langCtx]}</div>
              <p></p>
              <div>
                <b> {text.dis18[langCtx]} </b>
              </div>
              <p></p>
              <div>{text.dis19[langCtx]}</div>
            </div>
          </div>
        </p>
      </Modal.Body>
    </Modal>
  );
}

const text = {
  titlePrivacy: {
    en: "Privacy Policy and Confidentiality of Information",
    ar: "سياسة الخصوصية وسرية المعلومات ",
  },
  disPrivacy: {
    en: "The team at (Moment Healthcare) welcomes you and expresses gratitude for your trust. (Moment Healthcare) informs you that, in recognition of the user's rights, (Bouki Experimental Clinics) is committed to preserving user information in accordance with the privacy and confidentiality policy implemented at (Bouki Experimental Clinics). Accordingly, (Moment Healthcare) clarifies that the privacy policy and confidentiality of information, under which your information will be handled, is as follows:",
    ar: "يرحّب بكم فريق عمل (لحظة الرعاية الطبية)، ويتقدّم إليكم بالشكر على ثقتكم به، ويفيدكم (لحظة الرعاية الطبية) بأنه حرصاً منه و لإدراكه التام بأن المستخدم له حقوق، فإن (مجمع عيادات بوكي التجريبي) يسعى للحفاظ على المعلومات الخاصة بالمستخدمين وفقاً لآلية سياسة الخصوصية وسرية المعلومات المعمول بها في (مجمع عيادات بوكي التجريبي) . وعليه فإن (لحظة الرعاية الطبية) يوضّح لكم بأن سياسة الخصوصية وسرية المعلومات التي سيتم التعامل مع معلوماتكم الخاصة بكم بموجبها كما يلي:",
  },
  dis2: {
    en: "Firstly: What information does (Moment Healthcare) collect and retain in its databases?",
    ar: "أولاً: المعلومات التي تحصل عليها (لحظة الرعاية الطبية) وتحتفظ بها في قواعد بياناتها؟",
  },
  dis3: {
    en: "1. Personal information of the user, such as name, age, email address, and national ID or residence permit number.",
    ar: "  1. المعلومات الشخصية الخاصة بالمستخدم، كالإسم والعمر والبريد الالكتروني، ورقم الهوية الوطنية أو رقم الإقامة.",
  },
  dis4: {
    en: "2. Personal login information of the user, such as username, password, email, and the security question for password recovery along with its answer.",
    ar: "2. معلومات الدخول الشخصية الخاصة بالمستخدم، مثل اسم المستخدم وكلمة السر والبريد الالكتروني، والسؤال الخاص باسترجاع كلمة السر وإجابته.",
  },
  dis5: {
    en: "3. The nature of the online platform may necessitate the collection of certain information related to cookies for electronic purposes that facilitate interaction between the platform and the user.",
    ar: "  3. قد تفرض طبيعة المنصة الالكترونية بعض المعلومات المتعلّقة بالكوكيز وذلك لأغراض الكترونية تسهّل التعامل بين المتجر والمستخدم.",
  },
  dis6: {
    en: "Secondly: Does (Moment Healthcare) share this information?",
    ar: "ثانياً: هل (لحظة الرعاية الطبية) تشارك هذه المعلومات؟",
  },
  dis7: {
    en: "1. Naturally, (Moment Healthcare) endeavors to retain this information in a manner that preserves the user's privacy, and (Bouki Experimental Clinics) only retains this information for the purpose of improving the quality of the online store and facilitating interaction between (Moment Healthcare) and the user.",
    ar: "  1. بطبيعة الحال فإن (لحظة الرعاية الطبية) تسعى بالاحتفاظ بهذه المعلومات بما يحفظ خصوصية المستخدم، و(مجمع عيادات بوكي التجريبي) لا تحتفظ بهذه المعلومات إلا بهدف تحسين جودة المتجر الإلكتروني وتيسير التعامل فيما بين (لحظة الرعاية الطبية) والمستخدم.",
  },
  dis8: {
    en: "2. As a general rule, only those responsible for (Moment Healthcare) have access to all of this information, and they will not disclose or broadcast it to others.",
    ar: "2. كقاعدة عامة فإن جميع هذه المعلومات لا تطلع عليها إلا القائمين على (لحظة الرعاية الطبية)، ولن يقوموا بنشرها أو بثها للغير. ",
  },
  dis9: {
    en: "2. As a general rule, only those responsible for (Moment Healthcare) have access to all of this information, and they will not disclose or broadcast it to others.",
    ar: " 2. كقاعدة عامة فإن جميع هذه المعلومات لا تطلع عليها إلا القائمين على (لحظة الرعاية الطبية)، ولن يقوموا بنشرها أو بثها للغير.",
  },
  dis10: {
    en: "3. As (Moment Healthcare) seeks to safeguard the well-being of users, in the event of observing any irregular or unlawful activity by the user, (Bouki Experimental Clinics) may, after consultation with the legal counsel of (Moment Healthcare), report such activity to the relevant authorities.",
    ar: " 3. حيث أن (لحظة الرعاية الطبية) تسعى للحفاظ على سلامة المستخدمين، فإنه – في حالة ملاحظة (لحظة الرعاية الطبية) لأي نشاط غير نظامي أو غير شرعي يقوم به المستخدم – فإن (مجمع عيادات بوكي التجريبي) قد يقوم بإبلاغ الجهات المختصة بعد التشاور مع المحامي الخاص بـ (لحظة الرعاية الطبية).",
  },
  dis11: {
    en: "Thirdly: What is the extent of the security and confidentiality of the store's information?",
    ar: " ثالثاً: ما هو مدى أمان سرية المعلومات الخاصة بالمتجر؟ ",
  },
  dis12: {
    en: "1. (Moment Healthcare) strives for the confidentiality of information and the privacy policy for users and will not violate the provisions of these rules and policies. However, due to the impossibility of guaranteeing this 100% over the Internet, the (Moment Healthcare) team emphasizes the following:",
    ar: "1. تسعى (لحظة الرعاية الطبية) إلى سرية المعلومات وسياسة الخصوصية الخاصة بالمستخدمين و لن تخالف أحكام هذه القواعد والسياسة. ولكن نظراً لعدم إمكانية ضمان ذلك 100% عبر وسائل الإنترنت فإن فريق عمل (لحظة الرعاية الطبية) ينوّه بما يلي:",
  },
  dis13: {
    en: "(Moment Healthcare) seeks to maintain all user-related information in strict confidence, ensuring that no one has access to it in violation of the established policy at (Moment Healthcare).",
    ar: "تسعى (لحظة الرعاية الطبية) بالحفاظ على جميع المعلومات الخاصة بالمستخدم وألا يطلع عليها أحد بما يخالف هذه السياسة المعمول بها في (لحظة الرعاية الطبية).",
  },
  dis14: {
    en: "(Moment Healthcare) works to protect this information through specific servers, secured by electronic protection systems, whether in the form of specific software or programming systems.",
    ar: "تعمل (لحظة الرعاية الطبية) على حماية هذه المعلومات عن طريق “سيرفرات معيّنة؟” محمية بموجب أنظمة الحماية الإلكترونية “نظامي برمجي أو Software معيّن؟”.",
  },
  dis15: {
    en: "However, due to the inability to guarantee the internet 100% against potential breaches or viruses affecting electronic protection systems and the protective walls employed by (Moment Healthcare), (Bouki Experimental Clinics) advises users to maintain complete confidentiality of their information. Users are urged not to disclose any information they consider highly important, as part of (Moment Healthcare)'s commitment to guiding and advising users.",
    ar: "غير أنه نظراً لأن شبكة الانترنت لا يمكن ضمانها 100% لما قد يطرأ من اختراق أو فيروسات على أنظمة الحماية الالكترونية و على جدران الحماية المعمول به في (لحظة الرعاية الطبية) فإن (مجمع عيادات بوكي التجريبي) تنصح المستخدمين بالحفاظ على معلوماتهم بسرية تامة، وعدم إفشاء أي معلومات يراها المستخدم هامة جداً له، وهذا حرصاً على (لحظة الرعاية الطبية) في توجيه وإرشاد المستخدمين.",
  },
  dis16: {
    en: "Fourthly: Is there a third party involved in the relationship between (Bouki Experimental Clinics) and the merchant?",
    ar: "رابعاً: هل هناك طرف ثالث في العلاقة بين (مجمع عيادات بوكي التجريبي) والتاجر؟",
  },
  dis17: {
    en: "Here, the privacy policy is defined concerning cases involving a third party, such as marketers, technicians, consumers, and any other person associated with the relationship.",
    ar: "“تحدد هنا سياسة الخصوصية فيما يتعلّق بالحالات التي يكون هناك طرفاً ثالث، كالمسوّقين، والتقنيين، والمستهلك، وأي شخص آخر مرتبط بالعلاقة”",
  },
  dis18: {
    en: "Fifthly: Rules and terms of use for (Moment Healthcare):",
    ar: "خامساً: قواعد وأحكام استخدام (لحظة الرعاية الطبية) :",
  },
  dis19: {
    en: "All commitments of (Moment Healthcare), user obligations, and all rights pertaining to the relationship between the user or consumer and (Moment Healthcare) are outlined in the 'Policies' link. These rules include the 'Privacy Policy and Confidentiality of Information,' derived from the agreement concluded between the user and (Bouki Experimental Clinics) regarding their legal and regulatory relationship. The Privacy Policy and Confidentiality of Information have been formulated to ensure the credibility and trust that (Moment Healthcare) is committed to providing to users.",
    ar: "إن جميع التزامات (لحظة الرعاية الطبية)، وجميع التزامات المستخدمين، وجميع الحقوق الواردة في العلاقة بين المستخدم أو المستهلك و(لحظة الرعاية الطبية)، موجودة هنا ” رابط السياسات “، حيث أن هذه القواعد هي “سياسة الخصوصية وسرية المعلومات” والمنبثقة من الاتفاقية التي أبرمت بين المستخدم و(مجمع عيادات بوكي التجريبي) بخصوص العلاقة النظامية والقانونية بينهما، وقد وضعت سياسة الخصوصية وسرية المعلومات لضمان المصداقية والثقة التي تحرص (لحظة الرعاية الطبية) على توفيرها للمستخدمين",
  },
};
