// bootstrap
import "bootstrap/dist/css/bootstrap.min.css";

// aos
import "aos/dist/aos.css";
import AOS from "aos";

// owl carousel
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

// Layout
import Layout from "./Components/Layout/Layout";

// lang
import { useContext, useEffect } from "react";
import { LangContext } from "./Context/LangContext";

export default function App() {
  useEffect(() => {
    AOS.init();
  }, []);

  const langCtx = useContext(LangContext);

  return (
    <div className="App" dir={langCtx === "ar" ? "rtl" : "ltr"}>
      <Layout />
    </div>
  );
}
